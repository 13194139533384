.product-rating-container {
  margin-left: auto;
  padding: 0.2vw 0.5vw 0.5vw 0vw;
  font-size: 1.5vw;
  white-space: nowrap;
}

.product-rating-icon {
  color: orange;
}

@media screen and (max-width: 576px) {
  .product-rating-container {
    font-size: 7vw;
    padding: 0.5vw 1vw 1vw 0vw;
  }
}
