.store-container {
  display: flex;
  /* margin: 0.5vw; */
  align-items: center;
  justify-content: flex-start;
  min-height: 90vh;
}

.store-container-empty {
  display: flex;
  /* margin: 0.5vw; */
  align-items: center;
  justify-content: center;
  /* width: 97vw; */
  min-height: 90vh;
}

.store-left-menu {
  display: flex;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  margin: 0.5vw;
  align-self: flex-start;
  flex-direction: column;
  /* min-height: 24.5vw; */
}

.store-left-menu-accordion {
  display: none;
  /* min-width: 0vw;
  max-width: 0vw; */
  /* width: 0vw;
  height: 0vh; */
  white-space: nowrap;
  align-self: flex-start;
  border-radius: 0.5vw;
}

.store-left-menu-main {
  padding: 0.5vw 1vw 0.8vw 0px;
  white-space: nowrap;
  width: 23.3vw;
  min-height: 26vw;
  align-self: flex-start;
  border-radius: 0.5vw;
}

.store-left-menu-collapse {
  padding: 0.5vw 1vw 1vw 0px !important;
}

.store-products-section {
  display: flex;
  flex-flow: row wrap;
  align-self: flex-start;
  opacity: 10%;
  margin-left: 0.5vw;
}

.store-products-section-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.store-menu-title-label {
  font-weight: 700;
  margin-bottom: 0.5vw;
  color: black;
  text-align: center;
  font-size: 1.6vw;
  padding: 0vw 0vw 0vw 1vw;
}

.store-left-menu-item-label {
  cursor: pointer;
  padding-left: 1vw;
  font-size: 1.5vw;
  font-weight: 500;
  color: dimgray;
}

.store-left-menu-item-quantity {
  cursor: pointer;
  padding-right: 0.5vw;
  font-size: 1.5vw;
  font-weight: 500;
  color: dimgray;
  font-style: italic;
}

.store-left-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-left-menu-item:hover,
.store-left-menu-item:focus {
  box-shadow: 0.2vw 0.1vw 0.5vw orange;
  background-color: orange;
  border-radius: 0px 0.3vw 0.3vw 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-left-menu-item:hover .store-left-menu-item-label {
  color: cornflowerblue;
}

.store-left-menu-item:hover .store-left-menu-item-quantity {
  color: cornflowerblue;
}

.store-left-menu-item-selected {
  cursor: pointer;
  box-shadow: 0.2vw 0.1vw 0.5vw rgb(255, 204, 0);
  background-color: rgb(255, 204, 0);
  border-radius: 0px 0.3vw 0.3vw 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-left-menu-item-selected:hover,
.store-left-menu-item-selected:focus {
  box-shadow: 0.2vw 0.1vw 0.5vw orange;
  background-color: orange;
  border-radius: 0px 0.3vw 0.3vw 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-left-menu-item-selected .store-left-menu-item-label {
  color: rgb(0, 51, 255);
}

.store-left-menu-item-selected .store-left-menu-item-quantity {
  color: rgb(0, 51, 255);
}

.store-no-categories {
  font-size: 2.5vw;
  text-align: center;
}

/* @media screen and (max-width: 992px) {
  .store-container {
    display: flex;
    margin: 0.5vw;
    align-items: center;
    justify-content: flex-start;
    width: 97vw;
    min-height: 93vh;
    align-self: flex-start;
  }
} */

@media screen and (max-width: 576px) {
  .store-container {
    flex-flow: wrap;
    display: flex;
    margin: 0.5vw;
    align-items: center;
    justify-content: flex-start;
    width: 97vw;
    min-height: 93vh;
    flex-direction: column;
  }

  .store-container-empty {
    display: flex;
    margin: 0.5vw;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    padding: 2vw;
  }

  .store-products-section-empty {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    padding: 2vw;
    align-items: center;
    align-self: flex-start;
  }

  .store-left-menu {
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
    border-radius: 0.5vw;
    width: 96vw;
    margin: 1vw;
    align-self: flex-start;
    flex-direction: column;
  }

  .store-left-menu-accordion {
    display: flex;
    width: 96vw;
    white-space: nowrap;
    align-self: flex-start;
    border-radius: 0.5vw;
  }

  .store-products-section {
    display: flex;
    flex-flow: row wrap;
    align-self: flex-start;
    opacity: 10%;
    margin-left: 0vw;
  }

  .store-left-menu-accordion-item {
    border-top-left-radius: 0.5vw !important;
    border-top-right-radius: 0.5vw !important;
    border-bottom-left-radius: 0.5vw !important;
    border-bottom-right-radius: 0.5vw !important;
    width: 98vw;
  }

  .store-left-menu-accordion-header {
    font-weight: 700;
    margin-bottom: 0.5vw;
    color: black;
    text-align: center;
    border-top-left-radius: 0.5vw !important;
    border-top-right-radius: 0.5vw !important;
  }

  .store-left-menu-accordion-header-label {
    font-size: 7vw;
  }

  .store-left-menu-item-label {
    cursor: pointer;
    padding-left: 1vw;
    font-size: 7vw;
    color: dimgray;
  }

  .store-left-menu-item-quantity {
    cursor: pointer;
    padding-right: 0.5vw;
    font-size: 7vw;
    color: dimgray;
    font-style: italic;
  }

  .store-left-menu-main {
    display: none;
    padding: 0.5vw 1vw 1vw 0px;
    white-space: nowrap;
    min-width: 0vw;
    max-width: 0vw;
    width: 0vw;
    height: 0vh;
    align-self: flex-start;
    border-radius: 0.5vw;
    padding: 0;
  }
}
