.orders-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 93vh;
}

.orders-container-empty {
  display: flex;
  margin: 0.5vw;
  align-items: center;
  justify-content: center;
  width: 97vw;
  min-height: 93vh;
}

.orders-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 2vw;
  font-size: 2vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.orders-title-label {
  font-size: 4vw;
}

.orders-details-link {
  cursor: pointer;
  text-decoration: none;
}

.orders-empty-orders {
  padding: 3vw;
  font-size: 2.4vw;
}

.orders-buy-link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}

.orders-table {
  font-size: 3vw;
}

.orders-table-column-right {
  text-align: right;
}

.orders-table-cell-status-container {
  vertical-align: middle;
  text-align: center;
}

.orders-table-cell-status-container-icon {
  padding-right: 0.5vw;
  padding-left: 1vw;
}

.orders-table-cell-status-container-text {
  padding-right: 1vw;
}

.orders-container-empty {
  display: flex;
  margin: 0.5vw;
  align-items: center;
  justify-content: center;
  width: 97vw;
  min-height: 93vh;
}

/* @media screen and (max-width: 992px) {
  .orders-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93vh;
  }
}

@media screen and (max-width: 700px) {
  .orders-container-empty {
    display: flex;
    margin: 0.5vw;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    padding: 2vw;
  }
} */
