.store-product-info {
  display: flex;
  flex-flow: column;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  margin: 0.5vw;
  justify-content: stretch;
  align-items: stretch;
  background-repeat: no-repeat;
  width: 23.3vw;
  min-height: 26vw;
  background-size: 23.4vw;
}

.store-product-info-image {
  min-height: 12.2vw;
}

.store-product-info-name {
  padding: 0.5vw 0vw 0px 0.5vw;
  font-size: 1.4vw;
  color: black;
  text-align: left;
  font-weight: 700;
  margin: 1vw 0vw 0vw 0vw;
  white-space: nowrap;
}

.store-product-info-description {
  padding: 0vw 0vw 0px 0.5vw;
  font-size: 1.1vw;
  color: black;
  text-align: left;
  max-width: 23.2vw;
  margin: 0px;
}
.store-product-info-price {
  padding: 0.2vw 0vw 0.5vw 0.5vw;
  font-size: 1.5vw;
  color: black;
  text-align: left;
  margin: 0px;
  font-weight: 600;
}

.store-product-info-footer {
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  align-items: center;
}

.store-product-info-add-button {
  box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.25), 0 0.2vw 0.5vw 0.1vw dimgray;
  border-radius: 0px 0.5vw 0px 0.1vw;
  background-color: white;
  padding: 0.3vw;
  font-size: 1vw;
  color: dimgray;
  text-align: center;
  height: 2vw;
  width: 2vw;
  margin-left: auto;
}

.store-product-info-add-button:hover,
.store-product-info-add-button:focus {
  box-shadow: 0 0.2vw 0.5vw orange, 0 0.05vw 0.2vw 0.05vw black;
  background-color: orange;
  border-radius: 0px 0.5vw 0px 0.1vw;
  cursor: pointer;
}

.store-product-info-add-button:active {
  box-shadow: 0 0.2vw 0.5vw orange, 0 0.05vw 0.2vw 0.05vw black;
  background-color: darkorange;
  border-radius: 0px 0.5vw 0px 0.1vw;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .store-product-info {
    display: flex;
    flex-flow: column;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
    border-radius: 2vw;
    margin: 1vw;
    justify-content: stretch;
    align-items: stretch;
    background-repeat: no-repeat;
    width: 96vw;
    min-height: 24.5vw;
    background-size: 96vw;
  }

  .store-product-info-image {
    min-height: 55vw;
  }

  .store-product-info-name {
    padding: 1vw 0vw 0px 1vw;
    font-size: 7.5vw;
    color: black;
    text-align: left;
    font-weight: 700;
    margin: 1vw 0vw 0vw 0vw;
    white-space: nowrap;
  }

  .store-product-info-description {
    padding: 0vw 0vw 0px 1vw;
    font-size: 5vw;
    color: black;
    text-align: left;
    max-width: 98vw;
    margin: 0px;
  }
  .store-product-info-price {
    padding: 0.5vw 0vw 1vw 1vw;
    font-size: 7vw;
    color: black;
    text-align: left;
    margin: 0px;
    font-weight: 600;
  }

  .store-product-info-footer {
    display: flex;
    flex-flow: row;
    justify-content: stretch;
    align-items: center;
  }

  .store-product-info-add-button {
    box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.25), 0 0.2vw 0.5vw 0.1vw dimgray;
    border-radius: 0px 2vw 0px 0.5vw;
    background-color: white;
    padding: 0.3vw;
    font-size: 5vw;
    color: dimgray;
    text-align: center;
    height: 9vw;
    width: 10vw;
    margin-left: auto;
  }

  .store-product-info-add-button:hover,
  .store-product-info-add-button:focus {
    box-shadow: 0 0.2vw 0.5vw orange, 0 0.05vw 0.2vw 0.05vw black;
    background-color: orange;
    border-radius: 0px 2vw 0px 0.5vw;
    cursor: pointer;
  }

  .store-product-info-add-button:active {
    box-shadow: 0 0.2vw 0.5vw orange, 0 0.05vw 0.2vw 0.05vw black;
    background-color: darkorange;
    border-radius: 0px 2vw 0px 0.5vw;
    cursor: pointer;
  }
}
