.order-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 93vh;
}

.order-details-container-empty {
  display: flex;
  margin: 0.5vw;
  align-items: center;
  justify-content: center;
  width: 97vw;
  min-height: 93vh;
}

.order-details-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 2vw;
  font-size: 1.4vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.order-details-title-label {
  font-size: 3vw;
}

.order-details-table {
  font-size: 2vw;
}

.order-details-link-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.order-details-link {
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5vw;
}

.order-details-empty-link {
  cursor: pointer;
  text-decoration: none;
  font-size: 2.4vw;
}

.order-details-empty-orders {
  padding: 3vw;
  font-size: 2.4vw;
}

.order-details-buy-link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}

.order-details-table-cell-status-container {
  vertical-align: middle;
  text-align: center;
}

.order-details-table-cell-status-container-icon {
  padding-right: 0.5vw;
  padding-left: 1vw;
}

.order-details-table-cell-status-container-text {
  padding-right: 1vw;
}

.order-details-container-empty {
  display: flex;
  margin: 0.5vw;
  align-items: center;
  justify-content: center;
  width: 97vw;
  min-height: 93vh;
}

/* @media screen and (max-width: 992px) {
  .order-details-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93vh;
  }

  .order-details-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 2vw;
    padding: 4vw;
    font-size: 1vw;
    grid-template-columns: 1fr;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
     border-radius: 0.5vw;
  }

  .order-details-title-label {
    font-size: 2vw;
  }

  .order-details-empty-orders {
    padding: 0px;
    font-size: 3vw;
  }

  .order-details-link {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5vw;
  }

  .order-details-empty-link {
    cursor: pointer;
    text-decoration: none;
    font-size: 3vw;
    padding-top: 1vw;
  }
}

@media screen and (max-width: 700px) {
  .order-details-container-empty {
    display: flex;
    margin: 0.5vw;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    padding: 2vw;
  }
} */
